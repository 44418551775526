/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  right: 18px;
  top: 12px;
}

path {
  stroke: white;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 5vh !important;
}

/* General sidebar styles */
.bm-menu {
  background: transparent;
  font-size: 1.15em;
  height: 5vh !important;
  overflow: visible !important;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: right;
  overflow: visible;
  margin-right: 30px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}
