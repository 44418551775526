li {
  font-size: 18px;
}

.accordion__button {
  background: #222 !important;
  color: white !important;

  @media screen and (max-width: 500px) {
    max-width: 90%;
  }
}

a {
  color: inherit;
  text-decoration: initial;
}

.carousel .slide {
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
  max-width: 700px;
  table-layout: fixed;
}

td {
  text-align: center;
}

tr:nth-child(even) {
  background-color: #333;
}

tr {
  background-color: #444;
}
